@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(src/assets/fonts/fluro.woff2) format("woff2"),
         url(src/assets/fonts/fluro.woff) format("woff"),
         url(src/assets/fonts/fluro.otf) format("otf");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(src/assets/fonts/fluro_bold.woff2) format("woff2"),
         url(src/assets/fonts/fluro_bold.woff) format("woff"),
         url(src/assets/fonts/fluro_bold.otf) format("otf");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(src/assets/fonts/fluro_semibold.woff2) format("woff2"),
         url(src/assets/fonts/fluro_semibold.woff) format("woff"),
         url(src/assets/fonts/fluro_semibold.otf) format("otf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(src/assets/fonts/fluro_light.woff2) format("woff2"),
         url(src/assets/fonts/fluro_light.woff) format("woff"),
         url(src/assets/fonts/fluro_light.otf) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

