@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(/static/media/fluro.3f274467.woff2) format("woff2"),
         url(/static/media/fluro.a9b5b970.woff) format("woff"),
         url(/static/media/fluro.1a41d33a.otf) format("otf");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(/static/media/fluro_bold.1a74104d.woff2) format("woff2"),
         url(/static/media/fluro_bold.89394281.woff) format("woff"),
         url(/static/media/fluro_bold.75b0e81c.otf) format("otf");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(/static/media/fluro_semibold.12a7e0dc.woff2) format("woff2"),
         url(/static/media/fluro_semibold.f573d97c.woff) format("woff"),
         url(/static/media/fluro_semibold.81d6e9f0.otf) format("otf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'fluro';
    src: local('fluro'),
         url(/static/media/fluro_light.2ecffc99.woff2) format("woff2"),
         url(/static/media/fluro_light.81b21754.woff) format("woff"),
         url(/static/media/fluro_light.9fdb8a25.otf) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}


